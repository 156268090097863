<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="xl"
      class="modal-extended"
    >
      <CRow class="">
          <CCol sm="11" lg="6" class="pr-0">
            <CSelect
              :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-7'}"
              :label="$t('label.damageType')"
              addLabelClasses="required text-right"
              v-model.trim="damage.damageTypeId"
              :value.sync="damage.damageTypeId"
              :is-valid="hasError($v.damage.damageTypeId)" 
              :invalid-feedback="errorMessage($v.damage.damageTypeId)"
              :options="damageTypeOptions"
            />
          </CCol>
          <CCol sm="11" lg="6">
            <CSelect
              :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-8'}"
              :label="$t('label.localizacion')"
              addLabelClasses="required text-right"
              v-model.trim="damage.locationId"
              :value.sync="damage.locationId"
              :is-valid="hasError($v.damage.locationId)" 
              :invalid-feedback="errorMessage($v.damage.locationId)"
              :options="locationOptions"
            />
          </CCol>
          <CCol sm="11" lg="6" class="pr-0">
            <CSelect
              :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-7'}"
              :label="$t('label.inspectionMoment')"
              addLabelClasses="required text-right"
              v-model.trim="damage.inspectionMomentId"
              :value.sync="damage.inspectionMomentId"
              :is-valid="hasError($v.damage.inspectionMomentId)" 
              :invalid-feedback="errorMessage($v.damage.inspectionMomentId)"
              :options="momentInspectionOptions"
            />
          </CCol>
          <CCol sm="11" lg="6">
            <CInput
              :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-8'}"
              :label="$t('label.responsible')"
              addLabelClasses="text-right"
              v-model.trim="damage.responsible"
              :value.sync="damage.responsible"
              :is-valid="hasError($v.damage.responsible)" 
              :invalid-feedback="errorMessage($v.damage.responsible)"
            />
          </CCol>
          <CCol sm="11" lg="6" class="pr-0">
            <CInput
              :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-7'}"
              :label="$t('label.height')+'(CM)'"
              addLabelClasses="text-right"
              type="number"
              min="0"
              v-model.trim="damage.height"
              :value.sync="damage.height"
              :is-valid="hasError($v.damage.height)" 
              :invalid-feedback="errorMessage($v.damage.height)"
            />
          </CCol>
           <CCol sm="11" lg="6">
            <CInput
              :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-8'}"
              :label="$t('label.width')+'(CM)'"
              addLabelClasses="text-right"
              type="number"
              min="0"
              v-model.trim="damage.width"
              :value.sync="damage.width"
              :is-valid="hasError($v.damage.width)" 
              :invalid-feedback="errorMessage($v.damage.width)"
            />
          </CCol>
        <CCol sm="11" lg="6" class="pr-0">
          <div class="form-group form-row mb-0" rol="group">
            <label class="required col-form-label text-right col-sm-11 col-lg-5" for="hordadesde">
              {{$t('label.date')}}
            </label>
            <div class="col-sm-11 col-lg-7 input-group">
              <vue-datepicker
                valueType="format"
                format="DD/MM/YYYY"
                :open="openDate"
                :lang="this.$i18n.locale"
                @update:open="functionDate($event)"
                @clickoutside="openDate = false"
                @select="openDate = false"
                @pick="openDate = false"
                @close="openDate = false"
                :clearable="false"
                :disabled-date="validateDateRange"
                v-model.trim="$v.damage.date.$model"
                :append-to-body="false"
                class="w-100"
              >
                <template #input>
                  <CInput
                    addInputClasses="pr-0"
                    class="w-100 float-left"
                    v-uppercase
                    v-model="damage.date"
                    placeholder="DD/MM/YYYY"
                    :invalid-feedback="$t('label.required')"
                    :is-valid="hasError($v.damage.date)"
                    v-model.trim="$v.damage.date.$model"
                    @keypress="rightDate($event)"
                  >
                    <template #append-content>
                      <CIcon name="cil-calendar" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
        <CCol sm="11" lg="6">
          <div class="form-group form-row mb-0" rol="group">
            <label class="required col-form-label text-right col-sm-11 col-lg-4" for="hordadesde">
              {{$t('label.hour')}}
            </label>
            <div class="col-sm-11 col-lg-8 input-group">
              <vue-datepicker
                valueType="format"
                type="time"
                :format="'HH:mm'"
                v-model="damage.hour"
                :clearable="false"
                v-model.trim="$v.damage.hour.$model"
                :append-to-body="false"
                class="w-100"
              >
                <template #input>
                  <CInput
                    class="w-100 float-left"
                    v-uppercase
                    v-model="damage.hour"
                    placeholder="HH:MM"
                    :invalid-feedback="$t('label.required')"
                    :is-valid="hasError($v.damage.hour)"
                    v-model.trim="$v.damage.hour.$model"
                    @keypress="formatHourMMHH($event)"
                  >
                    <template #append-content>
                      <CIcon name="cil-clock" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
        <CCol sm="11" lg="6" class="pr-0">
          <CTextarea
            :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-7'}"
            :label="$t('label.observation')"
            addLabelClasses="text-right"
            v-uppercase
            v-model.trim="damage.observation"
            :value.sync="damage.observation"
            :is-valid="hasError($v.damage.observation)" 
            :invalid-feedback="errorMessage($v.damage.observation)"
          />
        </CCol>
        <CCol sm="12" lg="6" v-if="actualizar">
          <CSelect
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-8'}"
            addLabelClasses="text-right required"
            v-model="damage.status"
            :value.sync="damage.status"
            :is-valid="statusSelectColor"
            :options="selectOptions"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton outline color="add" @click="changeStatus()" >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal" >
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import mixinGeneral from '@/_mixins/general';
import mixinServicio from '@/_mixins/servicio';
import { mapState } from 'vuex';
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import inspectionValidation from '@/_validations/inspection/inspectionValidation';

function data() {
  return {
    openDate: false,
    damage: {
      damageTypeId: '',
      locationId: '',
      width: '',
      height: '',
      inspectionMomentId: '', 
      responsible: '',
      date: 'DD/MM/YYYY',
      hour: 'HH:MM',
      observation: '',
      visitCargoInspDamageId: '',
      TpDamageName: '',
      status: 1,
      FgActStatus: 1,
    },
    showModal: false,
    titleModal: '',
    loadingOverlay: false,
    isSubmit: false,
    newUnit: false,
    actualizar: false,
    //MODELO
    VisitId: '',

    //modelo daños
    momentInspectionOptions: [],
    damageTypeOptions: [],
    locationOptions: [],
    showTimePanel: false,
    previousDate: new Date(),
    laterDate: new Date(),
  };
}

function closeModal() {
  this.refreshComponent();
  this.$emit('child-refresh', true);
  this.showModal=false;
  this.openDate = false;
}

function functionDate(event) {
  if (event) {
    this.openDate = event;
  }
}

//lista de momento de la inspeccion
async function getMomentList() {
  this.loadingOverlay = true;
  this.momentInspectionOptions = [];
  let _this = this.$i18n.locale;
  
  let listado = Array;
  await this.$http.get("InspectionMoment-list", { })
  .then(response => {
    listado = [...response.data.data];
    this.momentInspectionOptions = listado.map(listado => Object.assign({}, this.momentInspectionOptions, {
      value: listado.InspectionMomentId,
      label: _this=='en' ? listado.InspectionMomentNameEn : listado.InspectionMomentNameEs,
    }));

    this.momentInspectionOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });
    
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
   this.loadingOverlay = false;
  });
}


//lista tipos de daños
async function getDamageTypeList() {
  this.loadingOverlay = true;
  this.damageTypeOptions = [];
  let _this = this.$i18n.locale;
  
  let listado = Array;
  await this.$http.get("VisitTpDamageL1-list", { })
  .then(response => {
    listado = [...response.data.data];
    this.damageTypeOptions = listado.map(listado => Object.assign({}, this.damageTypeOptions, {
      value: listado.TpDamageId,
      label: _this=='en' ? listado.TpDamageAbbrev+': '+listado.TpDamageNameEn : listado.TpDamageAbbrev+': '+listado.TpDamageNameEs,
    }));

    this.damageTypeOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });
    

  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

//lista de localizacion del daño
async function getLocationList(value) {
  this.loadingOverlay = true;
  this.locationOptions = [];
  let _this = this.$i18n.locale;
  
  let listado = Array;
  await this.$http.get("VisitTpCargoView-list", { TpCargoId: value })
  .then(response => {
    listado = [...response.data.data];
    this.locationOptions = listado.map(listado => Object.assign({}, this.locationOptions, {
      value: listado.TpCargoViewId,
      label: _this=='en' ? listado.TpCargoViewNameEn : listado.TpCargoViewNameEs,
    }));

    this.locationOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });
    
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

function refreshComponent() {
  this.damage.damageTypeId = '';
  this.damage.locationId = '';
  this.damage.width = '';
  this.damage.height = '';
  this.damage.inspectionMomentId = '';
  this.damage.responsible = '';
  this.damage.hour = '';
  this.damage.date = '';
  this.damage.observation = '';
  this.damage.visitCargoInspDamageId = '';
  this.damage.status = 1;
  this.damage.FgActStatus = 1;
  this.damage.TpDamageName = '';
  this.showTimePanel = false;
  this.openDate = false;
  this.$v.damage.$reset();
}

async function submit(){
  try {
    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.damage.$touch();

    if (this.$v.damage.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let dateNow = new Date();
    let currentTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatTimeZoneToDateTime(dateNow));
    let currentDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatTimeZoneToDateTime(dateNow));
    let startTime = (this.itinerarySelected.Ata==undefined) ?
      DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)) :
      DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
    let startDate = (this.itinerarySelected.Ata==undefined) ?
      DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)) :
      DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));

    if((this.damage.date === currentDate) && (this.damage.hour > currentTime)){
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsCurrentDateTimePleaseCheck');
    }

    if((this.damage.date === startDate) && (this.damage.hour < startTime)){
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsStartDateTimePleaseCheck');
    }

    const CargoJson = {
      TpCargoViewId: this.damage.locationId,
      TpDamageId: this.damage.damageTypeId,
      Width: this.damage.width,
      Height: this.damage.height,
      Observation: this.damage.observation, 
      Responsible: this.damage.responsible,  
      InspectionMomentId: this.damage.inspectionMomentId,  
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.damage.date+' '+this.damage.hour),
    };
    
    if(this.actualizar){
      CargoJson.VisitCargoInspDamageId= this.damage.visitCargoInspDamageId;
      CargoJson.Status= this.damage.status;
    }else{
      CargoJson.VisitCargoId = this.VisitCargoId;
    };

    let method = this.actualizar? 'PUT':'POST';
    let ruta = this.actualizar? 'VisitCargoInspDamage-update':'VisitCargoInspDamage-insert';

    await this.$http
      .ejecutar (method, ruta, CargoJson, { root: 'CargoJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
         //this.$emit('child-refresh');
          this.closeModal();
          this.loadingOverlay = false;
          this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡Exito!',
            text: response.data.data[0].Response,
            type: "success"
          });
        }
      })
      .catch((e) => {
        this.loadingOverlay = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: "error"
        });
      }).then(() => {
      this.isSubmit = false;
    });
      
  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
} 

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}


function getDateRange(){
    let currentDate = new Date();

    if(this.itinerarySelected.Ata!=undefined){ 
        this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
    }

    if(this.itinerarySelected.Ata==undefined){
        this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
    }

    if(this.itinerarySelected.Atd!=undefined){  
        this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
    }

    if(this.itinerarySelected.Atd==undefined){
        this.laterDate = currentDate;
    }
  }

  function statusSelectColor(){
    return this.damage.status === 1;
  }

function changeStatus() {
  if(this.damage.FgActStatus !== this.damage.status){              
    this.$swal
      .fire(this.alertProperties({
        text: `${this.$t('label.changeStatusQuestion')+' '} ${this.ContainerCode+': '+this.damage.TpDamageName}?`,
      }))     
      .then((result) => {
      if (result.isConfirmed) {
          this.submit();
      }
    })
  }else{
    this.submit();
  }
}

//computed

function selectOptions(){
  return [
      { 
        value: true, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: false, 
        label: this.$t('label.INACTIVO')
      }
    ];
}

export default {
  name: 'modal-edit-damage',
  data,
  props: {
    modal: null,
    TpCargoId: {
      type: String,
    },
    VisitCargoId: {
      type: String,
    },
    ContainerCode: {
      type: String,
    },
  },
  mixins: [mixinGeneral, mixinServicio],
  directives: UpperCase,
  validations:inspectionValidation,
  watch: {
    modal: async function () {
      if (this.modal) {
        this.showTimePanel = false;
        
        if (this.modal==true) {
          this.refreshComponent();
          this.actualizar  = false;
          this.titleModal = this.$t('label.newDamage')+' - '+this.ContainerCode;
        } else {
          this.actualizar  = true;
          this.titleModal  = this.$t('label.editDamage')+' - '+this.ContainerCode+': '+this.modal.TpDamageName;
          this.damage.TpDamageName = this.modal.TpDamageName;
          this.damage.damageTypeId = this.modal.TpDamageId;
          this.damage.locationId = this.modal.TpCargoViewId;
          this.damage.width = this.modal.Width;
          this.damage.height = this.modal.Height;
          this.damage.inspectionMomentId = this.modal.InspectionMomentId;
          this.damage.responsible = this.modal.Responsible;
          this.damage.hour = this.modal.TransactionDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.modal.TransactionDate)) : '';
          this.damage.date = this.modal.TransactionDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.modal.TransactionDate)) : '';
          this.damage.observation = this.modal.Observation;
          this.damage.visitCargoInspDamageId = this.modal.VisitCargoInspDamageId;
          this.damage.status = this.modal.FgActVisitCargoInspDamage ? 1:0;
          this.damage.FgActStatus = this.modal.FgActVisitCargoInspDamage ? 1:0;
          this.$v.damage.$touch();

        }
        this.showModal = true;   
        await this.getMomentList();
        await this.getDamageTypeList();
        this.getLocationList(this.TpCargoId);    
        this.getDateRange(); 
        this.$emit('closeModal');
      }
    },
  },
  methods: {
    closeModal,
    functionDate,
    getMomentList,
    getDamageTypeList,
    getLocationList,
    refreshComponent,
    submit,
    validateDateRange,
    getDateRange,
    statusSelectColor,
    changeStatus,
  },
  computed: {
    selectOptions,
    ...mapState({
      Visit: state => state.visitas.VisitId,
      ItineraryId: state => state.visitas.ItineraryId,
      itinerarySelected: state => state.visitas.itinerarySelected,         
    }),
  },
};
</script>
<style lang="scss">

</style>
